import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ListPage from '../components/BlogV2/ListPage'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <ListPage {...pageContext} />
    </Layout>
  )
}
